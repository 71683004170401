<template>
  <div class="container" v-if="waiver">
    <HMModalHeader
      :title="$t('WAIVER.LABEL.LEGAL_WAIVER')"
      @close="showCloseButton && $attrs._close()"
    />
    <div class="my-3">
      {{ $t('WAIVER.TEXT.WAIVER_NOTICE') }}
    </div>
    <HMCustomContent
      :html="waiver.waiver"
      :json="waiver.waiver_new"
      class="custom-content rounded bg-lightest-gray p-3"
      @scroll="checkIfScrollToBottom"
      ref="waiverContent"
    />
    <div
      class="my-4 w-100 d-flex flex-column flex-md-row justify-content-between align-items-center"
    >
      <span>
        {{ $t('GENERAL.LABEL.CREATED') }}:
        {{ $formatDate(waiver.created, 'date-word') }}
      </span>
      <HMButton
        color="brand"
        class="accept-waiver-btn text-center mt-2"
        :disabled="!isScrolledToBottom || isLoading"
        @click="signWaiver"
      >
        <span class="w-100">{{ $t('WAIVER.LABEL.ACCEPT_WAIVER') }}</span>
      </HMButton>
    </div>
  </div>
</template>

<script lang="ts">
import { errorToMessage } from '@/utils/errors';
import { mapState } from 'pinia';
import { useStudioStore, useUserStore } from '@/stores';

export default {
  name: 'LegalWaiverModal',
  props: {
    waiver: {
      type: Object,
    },
    showCloseButton: {
      type: Boolean,
    },
    uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isScrolledToBottom: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(useStudioStore, {
      currentStudioId: 'currentStudioId',
    }),
  },
  methods: {
    async signWaiver() {
      this.isLoading = true;
      try {
        const dispatchPromise = await useUserStore().signWaiver({
          studio_id: this.currentStudioId,
          waiver_id: this.waiver?.id,
          uuid: this.uuid,
        });
        await useStudioStore().getStudio(this.$studioURL);
        this.$attrs._close()
        this.$emit('onSubmit', dispatchPromise);
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t('NOTIFICATIONS.TITLE.SUCCESS'),
          text: this.$t('WAIVER.NOTIFICATIONS.SIGN_SUCCESS'),
        });
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: this.$t('WAIVER.NOTIFICATIONS.SIGN_ERROR'),
          text: errorToMessage(error?.response, `Request failed: ${error}`),
          duration: -1, // stay ON
        });
      } finally {
        this.isLoading = false;
      }
    },
    checkIfScrollToBottom({
      target: { scrollTop, clientHeight, scrollHeight },
    }) {
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        this.isScrolledToBottom = true;
      }
    },
  },
  mounted() {
    this.checkIfScrollToBottom({ target: this.$refs.waiverContent.$el });
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.custom-content {
  height: 400px;
  overflow: auto;
}
.accept-waiver-btn {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: auto;
  }
}
</style>
